// ProductContext.js

import React, { createContext, useState } from "react";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [orderId, setOrderId] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const [codChargeOtp, setCodChargeOtp] = useState("");
  const [payCodData, setPayCodData] = useState("");


  return (
    <ProductContext.Provider value={{ products, setProducts,category,setCategory,searchKey, setSearchKey, invoiceData,  setInvoiceData, orderId, setOrderId , paymentOption, setPaymentOption, codChargeOtp, setCodChargeOtp , payCodData, setPayCodData}}>
      {children}
    </ProductContext.Provider>
  );
};
